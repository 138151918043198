import React, { useState } from "react";
import styled from "styled-components";
import Img from "../../../assets/images/seminar.jpg";
import ShareModal from "../ShareModal";

function MomentSinglePageSpotlight() {
    const [isShare, setIsShare] = useState(false);
    return (
        <MainContainer>
            <LeftBox>
                <Title>
                    An orientation program on Talrop's Startup School and
                    Management School was organised at MES College, Marampally
                    <ShareBtn onClick={() => setIsShare(!isShare)}>
                        Share this moment
                    </ShareBtn>
                </Title>
                <Location>MES College, Marampally</Location>
                <Date>Sept. 29, 2022</Date>
                <Description>
                    An orientation program on Talrop's Startup School and
                    Management School was organised at MES College, Marampally
                    An orientation program on Talrop's Startup School was
                    organised at MES College, Marampally on the 29th of
                    September. Ajeesh Satheesan, Director & CMO of Talrop, led
                    the session on "Building a strong startup ecosystem in
                    Kerala".
                </Description>
            </LeftBox>
            <RightBox>
                <Image src={Img} alt="image" />
                <ShareModal isShare={isShare} />
            </RightBox>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;
const LeftBox = styled.div`
    width: 48%;
    @media all and (max-width: 1080px) {
        width: 100%;
    }
`;
const Title = styled.div`
    font-size: 32px;
    font-family: "gordita_bold";
    margin-bottom: 40px;
    position: relative;
    &::after {
        content: "";
        width: 30%;
        height: 5px;
        background-color: #28cc8b;
        position: absolute;
        left: 0;
        bottom: -8px;
    }
    @media all and (max-width: 1200px) {
        font-size: 28px;
    }
    @media all and (max-width: 850px) {
        font-size: 24px;
    }
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
`;
const ShareBtn = styled.div`
    cursor: pointer;
    color: #28cc8b;
    font-size: 16px;
    font-family: "gordita_regular";
    position: absolute;
    right: 0;
    bottom: -8px;
    @media all and (max-width: 685px) {
        bottom: -30px;
    }
`;
const Location = styled.p`
    color: #28cc8b;
    font-size: 16px;
    margin-bottom: 5px;
`;
const Date = styled.p`
    font-size: 15px;
    margin-bottom: 10px;
`;
const Description = styled.p`
    font-size: 16px;
`;
const RightBox = styled.div`
    position: relative;
    width: 48%;
    @media all and (max-width: 1080px) {
        width: 80%;
        margin: 0 auto 30px;
    }
`;
const Image = styled.img`
    display: block;
    width: 100%;
`;
export default MomentSinglePageSpotlight;
