import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../../screens/About";
import Home from "../../screens/Home";
// import Index from "../../screens/Index";
import JoinPage from "../../screens/JoinPage";
import Kerala from "../../screens/Kerala";
import MomentSinglePage from "../../screens/MomentSinglePage";
import Programs from "../../screens/Programs";
import VenueRegisterForm from "../../includes/VenueRegisterForm";
import DateModal from "../../includes/modal/DateModal";
import VenueImageSection from "../../VenueImageSection";
import FacultyRegisterForm from "../../includes/FacultyRegisterForm";
import Header from "../../includes/Header";
import Footer from "../../includes/Footer";
import AuthRouter from "./AuthRouter";
function AppRouter() {
    return (
        <div>
            <Header />
            <Routes>
                <Route>
                    <Route path="/signup/*" element={<AuthRouter />} />
                    {/* <Route path="/signup/venue-register" element={<VenueRegisterForm />} />
                    <Route path="/signup/faculty-register" element={<FacultyRegisterForm />} /> */}
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/moment-single-page" element={<MomentSinglePage />} />
                    <Route path="/Programs" element={<Programs />} />
                    <Route path="/moments" element={<Home />} />
                    <Route path="/kerala" element={<Kerala />} />
                    <Route path="/date" element={<DateModal />} />
                    <Route path="/venue-image" element={<VenueImageSection />} />
                </Route>
            </Routes>
            <Footer />
        </div>
    );
}

export default AppRouter;
