import React from "react";
import styled from "styled-components";
import Bg from "../../../assets/images/ekerala-sprite.png";

function Mission() {
    return (
        <Container>
            <div className="wrapper">
                <Heading>Mission E-Kerala 2025</Heading>
                <Description>A non-profit project</Description>
                <Contents>
                    <Card>
                        <TopSection></TopSection>
                        <BottomSection>
                            <Dates>2018 January 1</Dates>
                            <p>To</p>
                            <Dates>2025 December 31</Dates>
                        </BottomSection>
                    </Card>
                    <Card>
                        <TopSection className="clock"></TopSection>
                        <BottomSection>
                            <Dates>6 Years </Dates>
                            <Dates>72 Months</Dates>
                            <Dates>2191 Days</Dates>
                        </BottomSection>
                    </Card>
                    <Card>
                        <TopSection className="volunteers"></TopSection>
                        <BottomSection>
                            <Dates>100+ Engineers</Dates>
                            <Dates>2000+ Faculties</Dates>
                            <Dates>1000+ Volunteers</Dates>
                        </BottomSection>
                    </Card>
                </Contents>
            </div>
        </Container>
    );
}

export default Mission;
const Container = styled.div`
    width: 100%;
    padding: 50px 0 20px;
`;
const Heading = styled.h1`
    text-align: center;
    font-family: "gordita_medium";
    color: #333;
    font-size: 28px;
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 22px;
    }
`;
const Description = styled.p`
    font-family: "gordita_regular";
    font-size: 16px;
    text-align: center;
    color: #333;
`;
const Contents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    @media all and (max-width: 768px) {
        flex-direction: column;
    }
`;
const Card = styled.div`
    width: 32.5%;
    padding: 45px 0;
    border: dashed 2px #d9d9d9;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    :hover {
        border: dashed 2px #28cc8b;
    }
    @media all and (max-width: 1080px) {
        padding: 40px 0;
    }
    @media all and (max-width: 980px) {
        padding: 20px 0 30px;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const TopSection = styled.div`
    width: 32%;
    margin: 0 auto;
    height: 120px;
    background: url(${Bg}) no-repeat 18px 11px;
    &.clock {
        height: 122px;
        width: 26%;
        background: url(${Bg}) no-repeat -100px 11px;
        @media all and (max-width: 1280px) {
            width: 34%;
        }
        @media all and (max-width: 980px) {
            width: 39%;
            background: url(${Bg}) no-repeat -108px 11px;
        }
        @media all and (max-width: 768px) {
            width: 16%;
            background: url(${Bg}) no-repeat -99px 11px;
        }
        @media all and (max-width: 640px) {
            width: 22%;
        }
        @media all and (max-width: 480px) {
            width: 29%;
        }
        @media all and (max-width: 360px) {
            @media all and (max-width: 360px) {
                background: url(${Bg}) no-repeat -110px 11px;
            }
        }
    }
    &.volunteers {
        height: 122px;
        width: 25%;
        background: url(${Bg}) no-repeat -200px 11px;
        @media all and (max-width: 1280px) {
            width: 34%;
        }
        @media all and (max-width: 980px) {
            width: 43%;
            background: url(${Bg}) no-repeat -203px 11px;
        }
        @media all and (max-width: 768px) {
            width: 18%;
        }
        @media all and (max-width: 640px) {
            width: 22%;
        }
        @media all and (max-width: 480px) {
            width: 29%;
        }
        @media all and (max-width: 360px) {
            background: url(${Bg}) no-repeat -207px 11px;
            width: 30%;
        }
    }
    @media all and (max-width: 1280px) {
        width: 37%;
    }
    @media all and (max-width: 1080px) {
        width: 41%;
    }
    @media all and (max-width: 980px) {
        width: 51%;
        background: url(${Bg}) no-repeat 15px 11px;
    }
    @media all and (max-width: 768px) {
        width: 20%;
    }
    @media all and (max-width: 640px) {
        width: 29%;
    }
    @media all and (max-width: 480px) {
        width: 36%;
    }
    @media all and (max-width: 360px) {
        background: url(${Bg}) no-repeat 4px 11px;
    }
`;
const BottomSection = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Dates = styled.h5`
    font-size: 16px;
    font-family: "gordita_medium";
    color: #333;
`;
