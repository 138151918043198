import React from "react";
import styled from "styled-components";
import ProgramImg from "../../assets/images/program.png";
import Heading from "../includes/Heading";
import ProgramCard from "../includes/programs/ProgramCard";

function Programs() {
    const ProgramsData = [
        {
            id: 1,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 2,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 3,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 4,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 5,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 6,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 7,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
        {
            id: 8,
            image: ProgramImg,
            title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
            subtitle:
                "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        },
    ];
    return (
        <MainContainer id="main">
            <div className="wrapper">
                <TopSection>
                    <Heading
                        title={"Our Programs"}
                        subtitle={"Programs we Offer"}
                    />
                </TopSection>
                <BottomSection>
                    {ProgramsData.map((item) => (
                        <ProgramCard item={item} />
                    ))}
                </BottomSection>
            </div>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    padding: 60px 0;
`;
const TopSection = styled.div`
    margin-bottom: 40px;
`;

const BottomSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 40px;
    @media all and (max-width: 1280px) {
        grid-column-gap: 40px;
    }
    @media all and (max-width: 1080px) {
        grid-gap: 30px;
    }
    @media all and (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
    }
    @media all and (max-width: 768px) {
        grid-gap: 30px;
    }
    @media all and (max-width: 640px) {
        grid-gap: 20px;
    }
    @media all and (max-width: 520px) {
        grid-template-columns: 1fr;
    }
`;

export default Programs;
