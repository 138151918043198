import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GoThreeBars } from "react-icons/go";
import styled from "styled-components";
import { NavData } from "../datas/NavData";
import Hamburger from "../../assets/images/Handburg.svg";
import HamburgerMenu from "./HamburgerMenu";
import $ from "jquery";

function Header({ style = {} }) {
    const [isMenuModal, setIsMenuModal] = useState(false);
    useEffect(() => {
        if (isMenuModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isMenuModal]);

    const location = useLocation();

    return (
        <Container style={{ ...style }}>
            <Section className="wrapper">
                <LeftContainer>
                    <LinkTag to="/">
                        <LogoContainer>
                            <LogoImage
                                src={require("../../assets/images/ekerala.png")}
                                alt="logo"
                            />
                        </LogoContainer>
                    </LinkTag>
                </LeftContainer>
                <RightContainer>
                    <ListItems>
                        {NavData.map((item) => {
                            return (
                                <Items
                                    className={location.pathname === item.path && "isActive"}
                                    to={item.path}
                                >
                                    {item.name}
                                </Items>
                            );
                        })}
                    </ListItems>
                </RightContainer>
                <HamburgerBox onClick={() => setIsMenuModal(!isMenuModal)}>
                    <HamburgerImg src={Hamburger} alt="hamburger" />
                </HamburgerBox>
            </Section>
            <HamburgerMenu isMenuModal={isMenuModal} setIsMenuModal={setIsMenuModal} />
        </Container>
    );
}

export default Header;
const Container = styled.div`
    width: 100%;
    padding: 25px 0px;
    position: relative;
`;
const Section = styled.div`
    display: flex;
    justify-content: space-between;
`;
const LeftContainer = styled.div`
    width: 22%;
    cursor: pointer;
    @media all and (max-width: 900px) {
        width: 24%;
        display: flex;
        align-items: center;
    }
    @media all and (max-width: 640px) {
        width: 30%;
    }
    @media all and (max-width: 480px) {
        width: 35%;
    }
    @media all and (max-width: 360px) {
        width: 38%;
    }
`;
const LinkTag = styled(NavLink)``;
const LogoContainer = styled.div`
    &:hover {
        opacity: 0.9;
    }
`;
const LogoImage = styled.img`
    width: 100%;
    display: block;
`;
const RightContainer = styled.div`
    line-height: 90px;
    width: 45%;
    @media all and (max-width: 1280px) {
        width: 50%;
    }
    @media all and (max-width: 1080px) {
        width: 55%;
    }
    @media all and (max-width: 980px) {
        width: 60%;
    }

    @media all and (max-width: 768px) {
        display: none;
    }
`;
const ListItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Items = styled(NavLink)`
    font-size: 18px;
    color: #000;
    text-transform: capitalize;
    font-family: "gordita_regular";
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &.isActive {
        color: #25d06c;
    }
    &:last-child {
        cursor: pointer;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border-radius: 5px;
        width: 125px;
        background-color: #1ec569;
        height: 50px;
        font-family: gordita_regular;
        transition: all 0.3s ease 0s;
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const HamburgerBox = styled.div`
    width: 30px;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
    }
    @media all and (max-width: 360px) {
        width: 25px;
    }
`;
const HamburgerImg = styled.img`
    display: block;
    width: 100%;
`;
