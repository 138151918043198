import React, { useEffect } from "react";
import styled from "styled-components";
import AboutKerala from "./AboutKerala";
import GodsOwnCountry from "./GodsOwnCountry";
import OurKerala from "./OurKerala";

function Kerala() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <MainContainer id="main">
            <div className="wrapper">
                <AboutKerala />
                <OurKerala />
                <GodsOwnCountry />
            </div>
        </MainContainer>
    );
}
const MainContainer = styled.div``;

export default Kerala;
