import EventImg from "../../assets/images/eventimg.jpeg";

export const MomentData = [
    {
        id: 1,
        name: "AWARENESS",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 2,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 3,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 4,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 5,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 6,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 7,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 8,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 9,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 10,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
    {
        id: 11,
        name: "ORIENTATION",
        image: EventImg,
        date: "28 sept 2022",
        location: "Techies Park at Jamia Nadwiyya, Edavanna, Malappuram",
        title: "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
        subtitle:
            "Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. The programme was hosted at Talrop's Techies Park at Jamia Nadwiyya Campus Edavanna Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna. Talrop's eKerala mission conducted industrial visit for the students of EOLP School Edavanna.",
    },
];
