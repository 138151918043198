import Kasaragod from "../../assets/images/kasargod.jpg";
import Kannur from "../../assets/images/kannur.jpg";
import Wayanad from "../../assets/images/wayanad.jpg";
import Kozhikode from "../../assets/images/kozhikod.jpg";
import Malappuram from "../../assets/images/malappuram.jpg";
import Palakkad from "../../assets/images/palakkad.jpg";
import Thrissur from "../../assets/images/thrissur.jpg";
import Ernakulam from "../../assets/images/ernakulam.jpg";
import Idukki from "../../assets/images/idukki.jpg";
import Kottayam from "../../assets/images/kottayam.jpg";
import Alappuzha from "../../assets/images/alapuzha.jpg";
import Pathanamthitta from "../../assets/images/pathanamthitta.jpg";
import Kollam from "../../assets/images/kollam.jpg";
import Thiruvananthapuram from "../../assets/images/trivandrum.jpg";

export const DistrictData = [
    {
        id: 1,
        image: Kasaragod,
        district: "Kasaragod",
    },
    {
        id: 2,
        image: Kannur,
        district: "Kannur",
    },
    {
        id: 3,
        image: Wayanad,
        district: "Wayanad",
    },
    {
        id: 4,
        image: Kozhikode,
        district: "Kozhikode",
    },
    {
        id: 5,
        image: Malappuram,
        district: "Malappuram",
    },
    {
        id: 6,
        image: Palakkad,
        district: "Palakkad",
    },
    {
        id: 7,
        image: Thrissur,
        district: "Thrissur",
    },
    {
        id: 8,
        image: Ernakulam,
        district: "Ernakulam",
    },
    {
        id: 9,
        image: Idukki,
        district: "Idukki",
    },
    {
        id: 10,
        image: Kottayam,
        district: "Kottayam",
    },
    {
        id: 11,
        image: Alappuzha,
        district: "Alappuzha",
    },
    {
        id: 12,
        image: Pathanamthitta,
        district: "Pathanamthitta",
    },
    {
        id: 13,
        image: Kollam,
        district: "Kollam",
    },
    {
        id: 14,
        image: Thiruvananthapuram,
        district: "Thiruvananthapuram",
    },
];
