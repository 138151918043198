import React from "react";
import styled from "styled-components";
import RegisterCard from "../includes/RegisterCard";

function JoinPage() {
    const item = [
        {
            id: 1,
            image: require("../../assets/images/venue.png"),
            register: "Register Your venue",
            path: "venue-register",
        },
        {
            id: 2,
            image: require("../../assets/images/faculty.png"),
            register: "Register as a Faculty",
            path: "faculty-register",
        },
    ];
    return (
        <Container>
            <Section className="wrapper">
                <Heading>Connect with us</Heading>
                <Description>
                    Do you have a venue for us to conduct our programs? Or are you an expert in any
                    technology field willing to share your expertise? Register with us here.
                </Description>
                <Contents>
                    {item.map((item) => (
                        <RegisterCard item={item} />
                    ))}
                </Contents>
            </Section>
        </Container>
    );
}

export default JoinPage;
const Container = styled.div`
    width: 100%;
    padding-bottom: 40px;
`;
const Section = styled.div`
    margin-top: 40px;
`;
const Heading = styled.h1`
    text-align: center;
    color: #212121;
    font-family: "gordita_medium";
    @media all and (max-width: 768px) {
        font-size: 28px;
    }
`;
const Description = styled.p`
    color: #7e7e7e;
    font-size: 16px;
    font-family: "gordita_regular";
    width: 50%;
    margin: 30px auto 0;
    text-align: center;
    @media all and (max-width: 1280px) {
        width: 58%;
    }
    @media all and (max-width: 1080px) {
        width: 63%;
        font-size: 15px;
    }
    @media all and (max-width: 980px) {
        width: 75%;
    }
    @media all and (max-width: 768px) {
        width: 85%;
        margin-top: 20px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const Contents = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        margin-top: 20px;
    }
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
`;
