import React, { useState } from "react";
import styled from "styled-components";
import GalleryImg from "../../../assets/images/teaching.jpg";
import { ImageData } from "../../datas/ImageData";
import ImgsViewer from "react-images-viewer";

function MomentGallery({ isModal, setIsModal }) {
    const [isId, setIsId] = useState(0);
    const [isImageViewer, setImageViewer] = useState(false);

    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const handleImageViewer = () => {
        setImageViewer((prev) => !prev);
    };
    const previousImage = () => {
        console.log("clickes");
        setCurrentImgIndex(currentImgIndex - 1);
    };
    const nextImage = () => {
        setCurrentImgIndex(currentImgIndex + 1);
    };
    return (
        <MainContainer>
            <ImgsViewer
                imgs={ImageData}
                currImg={currentImgIndex}
                isOpen={isImageViewer}
                onClickPrev={previousImage}
                onClickNext={nextImage}
                onClose={handleImageViewer}
            />

            <Heading>Moment Gallery</Heading>
            <GalleryContainer>
                {ImageData.map((item, index) => (
                    <>
                        <GalleryItem
                            onClick={() => {
                                setImageViewer(true);
                                setCurrentImgIndex(index);
                            }}
                            key={item.id}
                        >
                            <GalleryImage
                                src={`${item.src}`}
                                alt="gallery-image"
                            />
                        </GalleryItem>
                    </>
                ))}
            </GalleryContainer>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    margin-bottom: 50px;
    @media all and (max-width: 520px) {
        margin-bottom: 30px;
    }
`;
const Heading = styled.div`
    font-size: 24px;
    margin-bottom: 30px;
    @media all and (max-width: 640px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const GalleryContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const GalleryItem = styled.div`
    min-width: 200px;
    height: 150px;
    margin-right: 20px;
    cursor: pointer;
`;
const GalleryImage = styled.img`
    display: block;
    width: 100%;
`;

export default MomentGallery;
