import React, { useContext, useEffect, useState, useRef } from "react";
import OtpInput from "react-otp-input";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import { ekeralaConfig } from "../../../axiosConfig";

// import ButtonLoader from "../includes/ButtonLoader";
// import { Context } from "../context/Store";

function OtpModal({
    isOtp,
    setOtp,
    otpVal,
    setPhone,
    phone,
    setOtpVal,
    country,
    isUpload,
    setUpload,
}) {
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [counter, setCounter] = useState(30);
    const [isOtpError, setOtpError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleChange = (otp) => {
        setOtp(otp);
    };

    // verify otp
    // const handleFormSubmit = () => {
    //     setLoading(true);
    //     ekeralaConfig
    //         .post("/wesb/member/registration/verify-otp/", {
    //             phone: phone,
    //             country: country,
    //             otp: otpVal,
    //         })
    //         .then(function (response) {
    //             const { StatusCode } = response.data;
    //             if (StatusCode === 6000) {
    //                 setLoading(false);
    //                 setOtpError(false);
    //             } else {
    //                 setError(true);
    //                 setOtpVal("");
    //                 setLoading(false);
    //                 setOtpError(true);
    //             }
    //         });
    // };
    return (
        <>
            {isOtp && (
                <BackContainer
                    style={{
                        opacity: isOtp && "1",
                        visibility: isOtp && "unset",
                    }}
                >
                    <Overlay onClick={() => setOtp(!isOtp)}></Overlay>
                    <Modal>
                        <TopSection>
                            <Left>
                                <Heading>Enter Verification Code</Heading>
                            </Left>
                            <Right>
                                <CloseContainer onClick={() => setOtp(false)}>
                                    <CloseIcon
                                        src={require("../../../assets/images/close.svg").default}
                                        alt="close"
                                    />
                                </CloseContainer>
                            </Right>
                        </TopSection>
                        <Bottom>
                            <Description>
                                We have send you a verification code to
                                {/* <Hr /> */}
                                <Span>+91</Span>
                                <Span>9876543210</Span>
                            </Description>
                            <MiddleCover>
                                <OtpSection className="otp-section">
                                    <OtpInput
                                        numInputs={4}
                                        placeholder={"0000"}
                                        inputStyle={error ? "error" : "OtpInput"}
                                        isInputNum={true}
                                        value={otpVal}
                                        onChange={(value) => handleChange(value)}
                                    />

                                    {isOtpError ? <Error>Invalid OTP</Error> : ""}
                                    <Resend counter={counter}>
                                        Resend Code?{" "}
                                        {/* <Timer onClick={() => handleResend()}>{counter}</Timer> */}
                                    </Resend>
                                </OtpSection>
                            </MiddleCover>
                            <ButtonSect>
                                <ErrorMessage>{errorMessage}</ErrorMessage>
                                {/* <ButtonLoader />
                                    </VerifyButton>
                                    ) : ( */}
                                <VerifyButton
                                    // to="/venue-image"
                                    onClick={() => {
                                        // handleFormSubmit();
                                        setUpload(true);
                                        setOtp(false);
                                    }}
                                >
                                    Verify
                                </VerifyButton>
                            </ButtonSect>
                        </Bottom>
                    </Modal>
                </BackContainer>
            )}
        </>
    );
}

export default OtpModal;
const BackContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
`;
const Overlay = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Modal = styled.div`
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 50%;
    max-width: 980px;
    max-height: 90vh;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    @media all and (max-width: 1080px) {
        width: 60%;
    }
    @media all and (max-width: 980px) {
        width: 77%;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 95%;
    }
    @media all and (max-width: 360px) {
        width: 90%;
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #e7e7e7;
`;
const Left = styled.div``;
const Heading = styled.h3`
    color: #003c3c;
    font-family: "gordita_medium";
    font-size: 22px;
    @media all and (max-width: 360px) {
        font-size: 17px;
    }
`;
const Right = styled.div`
    width: 20px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 15px;
    }
`;
const Bottom = styled.div`
    padding-top: 20px;
`;
const MiddleCover = styled.div``;
const Description = styled.p`
    font-size: 18px;
    color: #5a5a5a;
    width: 57%;
    @media all and (max-width: 1280px) {
        width: 65%;
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 980px) {
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 85%;
        font-size: 17px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const Hr = styled.hr`
    display: none;
    @media all and (max-width: 360px) {
        display: block;
    }
`;
const Span = styled.span`
    color: #1ec569;
    margin-left: 10px;
    font-size: 18px;
    @media all and (max-width: 360px) {
        font-size: 15px;
    }
`;
const OtpSection = styled.div`
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    div {
        width: 90% !important;
        margin: 0 auto !important;
        @media all and (max-width: 360px) {
            font-size: 16px;
        }
    }
    div div {
        display: flex !important;
        justify-content: center !important;
        margin-bottom: 40px !important;
    }
    div div input {
        color: #000 !important;
        font-size: 50px !important;
        border-bottom: 2px solid #c6c6c6 !important;
        display: flex !important;
        justify-content: space-between !important;
        @media all and (max-width: 1280px) {
            font-size: 45px !important;
        }
        @media all and (max-width: 1080px) {
            font-size: 40px !important;
        }
        @media all and (max-width: 980px) {
            font-size: 35px !important;
        }
        @media all and (max-width: 768px) {
            font-size: 30px !important;
        }
        @media all and (max-width: 640px) {
            font-size: 35px !important;
        }
        @media all and (max-width: 480px) {
            font-size: 35px !important;
        }
        @media all and (max-width: 360px) {
            font-size: 30px !important;
        }
    }
    &.OtpInput {
        width: 100%;
        height: 40px;
        border: red;
        background-color: red !important;
    }
`;
const Error = styled.span`
    color: red;
    position: absolute;
    right: 86px;
    bottom: 30px;
`;
const ErrorMessage = styled.p`
    color: red;
    position: absolute;
    right: 0;
    bottom: -25px;
    font-size: 12px;
`;
const ButtonSect = styled.div`
    margin-top: 65px;
    margin-bottom: 50px;
    @media (max-width: 480px) {
        margin-top: 25px;
    }
    @media (max-width: 540px) {
        margin-top: 40px;
    }
`;

const Resend = styled.div`
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    width: 34%;
    margin-left: auto;
    cursor: pointer;
`;
const Timer = styled.span`
    color: #9f51b0;
    font-size: 18px;
    margin-left: 5px;
`;
const VerifyButton = styled.button`
    width: 150px;
    height: 45px;
    background: #1ec569;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    float: right;
    box-sizing: border-box;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const CloseContainer = styled.div`
    width: 28px;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
