import React from "react";
import styled from "styled-components";

function OurKerala() {
    const data = [
        {
            id: 1,
            name: "District",
            count: "14",
        },
        {
            id: 2,
            name: "Parliament Constituency",
            count: "20",
        },
        {
            id: 3,
            name: "Panchayat",
            count: "900+",
        },
        {
            id: 4,
            name: "District",
            count: "14",
        },
        {
            id: 5,
            name: "Parliament Constituency",
            count: "20",
        },
        {
            id: 6,
            name: "Panchayat",
            count: "900+",
        },
        {
            id: 7,
            name: "District",
            count: "14",
        },
        {
            id: 8,
            name: "Parliament Constituency",
            count: "20",
        },
        {
            id: 9,
            name: "Panchayat",
            count: "900+",
        },
        {
            id: 10,
            name: "District",
            count: "14",
        },
        {
            id: 11,
            name: "Parliament Constituency",
            count: "20",
        },
        {
            id: 12,
            name: "Panchayat",
            count: "900+",
        },
        {
            id: 13,
            name: "District",
            count: "14",
        },
        {
            id: 14,
            name: "Parliament Constituency",
            count: "20",
        },
        {
            id: 15,
            name: "Panchayat",
            count: "900+",
        },
    ];
    return (
        <MainSection>
            <Heading>Our Kerala</Heading>
            <ContentContainer>
                {data.map((item) => (
                    <Content key={item.id}>
                        <Count>{item.count}</Count>
                        <Name>{item.name}</Name>
                    </Content>
                ))}
            </ContentContainer>
        </MainSection>
    );
}
const MainSection = styled.div`
    padding: 50px 0;
    @media all and (max-width: 768px) {
        padding: 40px 0;
    }
    @media all and (max-width: 640px) {
        padding: 30px 0;
    }
`;
const Heading = styled.h3`
    font-size: 32px;
    text-align: center;
    margin-bottom: 40px;
    font-family: "gordita_medium";
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media all and (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 22px;
    }
`;
const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    @media all and (max-width: 1280px) {
        grid-gap: 40px;
    }
    @media all and (max-width: 980px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media all and (max-width: 768px) {
        grid-gap: 30px;
    }
    @media all and (max-width: 640px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 420px) {
        grid-template-columns: 1fr;
    }
`;
const Content = styled.div`
    box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 10%), 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 14px;
    padding: 30px;
    @media all and (max-width: 1080px) {
        padding: 20px;
    }
    @media all and (max-width: 768px) {
        padding: 18px;
    }
`;
const Count = styled.div`
    color: #28cc8b;
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
    @media all and (max-width: 980px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const Name = styled.div`
    font-size: 16px;
    text-align: center;
    color: #606060;

    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
export default OurKerala;
