import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from "react";

function CategoryModal({
    viewModal,
    setViewModal,
    category,
    setSelectedCategories,
    selectedCategories,
}) {
    // console.log(viewModal, "-----------");
    const [categoryModal, setCategoryModal] = useState(false);

    const [allItems, setAllItems] = useState({});
    const [fields, setFields] = useState([]);
    const [isError, setError] = useState(false);
    const overlayRef = useRef(null);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();

        setSelectedCategories([...selectedCategories, ...fields]);
        setViewModal(false);
    };

    return (
        <>
            <Container>
                {/* <Overlay ref={overlayRef} onClick={() => setViewModal(false)}> */}
                <Content>
                    <Top>
                        {" "}
                        <Heading>Add your Categories</Heading>
                        <CloseContainer onClick={() => setViewModal(false)}>
                            <CloseIcon
                                src={require("../../../assets/images/close-icon.svg").default}
                                alt="close"
                            />
                        </CloseContainer>
                    </Top>
                    <Bottom>
                        {fields?.map((item, i) => (
                            <NameSection>
                                <Label>{item.name} *</Label>
                                <Input
                                    type="text"
                                    placeholder={item.placeholder}
                                    onChange={(e) => {
                                        // let filteredItems = fields.filter((_item) => {
                                        //     return _item.id !== item.id;
                                        // });
                                        let tempItem = fields.find((_item) => _item.id === item.id);
                                        tempItem.subject = e.target.value;
                                        let index = fields.findIndex(
                                            (_item) => _item.id === item.id
                                        );

                                        let _fields = [...fields];
                                        if (index !== -1) {
                                            _fields[index] = tempItem;
                                        }
                                        setFields(_fields);
                                    }}
                                    value={item.subject}
                                />
                            </NameSection>
                        ))}

                        <NameSection>
                            <Label>Categories *</Label>
                            <CategoryBox>
                                <CategoryName>Add your categories</CategoryName>
                                <ArrowContainer onClick={() => setCategoryModal(!categoryModal)}>
                                    <ArrowIcon
                                        src={require("../../../assets/images/add.svg").default}
                                        alt="next"
                                    />
                                </ArrowContainer>
                            </CategoryBox>
                            {categoryModal && (
                                <CategoryCover>
                                    {category?.map((item) => (
                                        <CategoryHeading
                                            key={item.id}
                                            onClick={(e) => {
                                                setAllItems(item);
                                                setCategoryModal(false);
                                                if (
                                                    !Boolean(
                                                        fields.find((itm) => itm.id === item.id)
                                                    )
                                                ) {
                                                    setFields([...fields, item]);
                                                }
                                            }}
                                        >
                                            {item.name}
                                        </CategoryHeading>
                                    ))}
                                </CategoryCover>
                            )}
                        </NameSection>
                    </Bottom>
                    <SubmitButton onClick={submitHandler}>Save</SubmitButton>
                </Content>
                {/* </Overlay> */}
            </Container>
        </>
    );
}

export default CategoryModal;
const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Content = styled.div`
    width: 700px;
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
    position: relative;
    @media all and (max-width: 1080px) {
        width: 650px;
    }
    @media all and (max-width: 980px) {
        width: 600px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 450px;
    }
    @media all and (max-width: 480px) {
        width: 345px;
        padding: 20px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Heading = styled.h2`
    font-family: "gordita_medium";
    font-size: 20px;
    color: #2d2d2d;
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #e7e7e7;
`;
const CloseContainer = styled.div`
    width: 15px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const Bottom = styled.div`
    /* display: flex;
    justify-content: space-between; */
    padding-top: 40px;
`;
const NameSection = styled.div`
    position: relative;
    margin-bottom: 25px;
    height: 85px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
`;
const Label = styled.label`
    color: #6b6b6b;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-bottom: 5px;
`;
const Input = styled.input`
    background: #ffffff !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    &::placeholder {
        color: #9f9f9f;
    }
`;

const ArrowContainer = styled.div`
    cursor: pointer;
    width: 4%;
    margin-right: 10px;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 480px) {
        width: 7%;
    }
`;
const ArrowIcon = styled.img`
    width: 100%;
    display: block;
`;

const SubmitButton = styled.div`
    cursor: pointer;
    color: #fff;
    display: flex;
    margin-left: auto;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    width: 190px;
    background-color: #1ec569;
    height: 50px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const ErrorMessage = styled.p`
    margin-top: 10px;
    font-size: 15px;
    color: #c43025;
    position: absolute;
    bottom: -34px;
`;
const CategoryName = styled.div``;
const CategoryBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 13px;
    font-size: 16px;
`;
const CategoryCover = styled.div`
    width: 100%;
    padding: 5px 0;
    background: #ffff;
    border-radius: 4px;
    z-index: 1000;
`;
const CategoryHeading = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 45px;
    padding: 0 25px;
    border-bottom: 1px solid #e7e7e7;
    color: #6b6b6b;
    font-family: "gordita_regular";
    cursor: pointer;
    &:hover {
        background: #e4f8ed;

        color: #4ca473;
    }
`;
