import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./assets/css/style.css";
import MainRouter from "./components/routing/routers/MainRouter";
import "../src/assets/css/style.css";

function App() {
    return (
        <>
            <Router>
                <MainRouter />
            </Router>
        </>
    );
}

export default App;
