import React from "react";
import styled from "styled-components";

function Footer() {
    return (
        <Container>
            <Section className="wrapper">
                <Top>
                    <LeftContainer>
                        <Copy>&copy;2022 ekerala.org. All rights reserved.</Copy>
                    </LeftContainer>
                    <MiddleContainer>
                        <IconContainer>
                            <Icons href="https://www.facebook.com/talropworld" target="_blank">
                                <Icon
                                    src={require("../../assets/images/facebookgray.svg").default}
                                    alt="facebook"
                                />
                            </Icons>
                            <Icons href="https://www.linkedin.com/company/talrop" target="_blank">
                                <Icon
                                    src={require("../../assets/images/linkedin.svg").default}
                                    alt="linkedin"
                                />
                            </Icons>
                            <Icons href="https://www.instagram.com/talropworld/" target="_blank">
                                <Icon
                                    src={require("../../assets/images/insta.svg").default}
                                    alt="instagram"
                                />
                            </Icons>
                            <Icons href="https://twitter.com/talropworld" target="_blank">
                                <Icon
                                    src={require("../../assets/images/twitter.svg").default}
                                    alt="twitter"
                                />
                            </Icons>
                        </IconContainer>
                    </MiddleContainer>
                    <RightContainer>
                        <Text>
                            Powered by{" "}
                            <a href="http://talrop.com/" target="_blank">
                                Talrop
                            </a>
                        </Text>
                    </RightContainer>
                </Top>
                <BottomContainer>
                    <Contents>
                        <Number href="tel:+9185899995555">+91 858 9999 555 |</Number>
                        <Number href="mailto:hello@ekerala.org">hello@ekerala.org</Number>
                    </Contents>
                </BottomContainer>
            </Section>
        </Container>
    );
}

export default Footer;
const Container = styled.div`
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 50px;
    @media all and (max-width: 480px) {
        padding: 40px 30px;
    }
`;
const Section = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 980px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
const Top = styled.div`
    display: flex;

    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;
const LeftContainer = styled.div`
    width: 33.3%;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const MiddleContainer = styled.div`
    width: 33.3%;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const RightContainer = styled.div`
    width: 33.3%;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const Copy = styled.p`
    font-size: 16px;
    @media all and (max-width: 1280px) {
        text-align: center;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const IconContainer = styled.div`
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 54%;
    }
    @media all and (max-width: 1080px) {
        width: 64%;
    }
    @media all and (max-width: 980px) {
        width: 54%;
        margin-top: 25px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 480px) {
        width: 66%;
    }
`;
const Icons = styled.a`
    width: 34px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #444;
    &:hover {
        opacity: 0.8;
    }
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;
const BottomContainer = styled.div`
    margin-top: 50px;
    @media all and (max-width: 980px) {
        margin-top: 25px;
    }
`;
const Contents = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 360px) {
        flex-direction: column;
        font-size: 14px;
    }
`;
const Number = styled.a`
    font-size: 18px;
    color: #fff;
    &:last-child {
        margin-left: 5px;
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;
const Text = styled.p`
    text-align: right;
    font-size: 16px;
    a {
        color: #fff;
        :hover {
            opacity: 0.8;
        }
    }
    @media all and (max-width: 1280px) {
        text-align: center;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
