import React from "react";
import { Route, Routes } from "react-router-dom";
import FacultyRegisterForm from "../../includes/FacultyRegisterForm";
import VenueRegisterForm from "../../includes/VenueRegisterForm";
import JoinPage from "../../screens/JoinPage";

function AuthRouter() {
    return (
        <Routes>
            <Route path="" element={<JoinPage />} />
            <Route path="venue-register" element={<VenueRegisterForm />} />
            <Route path="faculty-register" element={<FacultyRegisterForm />} />
        </Routes>
    );
}

export default AuthRouter;
