import React, { useState } from "react";
import styled from "styled-components";

function FacilityModal({ isFacility, setIsFacility, setSelectedItems }) {
    const [item] = useState([
        { id: 1, name: "None", value: "" },
        { id: 2, name: "Custom Facility", placeholder: "yes", value: "yes" },
        { id: 3, name: "Seating capacity", placeholder: "count", value: "" },
        { id: 4, name: "Air Conditioner", placeholder: "yes", value: "yes" },
        { id: 5, name: "Separate food area", placeholder: "count", value: "" },
        { id: 6, name: "Parking", placeholder: "count", value: "" },
        { id: 7, name: "Mic & Sound system", placeholder: "yes", value: "yes" },
        { id: 8, name: "LED Wall", placeholder: "yes", value: "yes" },
    ]);
    console.log(isFacility, "facility");

    return (
        <Cover className={isFacility ? "active" : ""}>
            <Container onClick={() => setIsFacility(false)}>
                <>
                    {item.map((data) => (
                        <Content
                            onClick={() =>
                                setSelectedItems((prev) => [...new Set(prev.concat(data))])
                            }
                        >
                            {data.name}
                        </Content>
                    ))}
                </>
            </Container>
        </Cover>
    );
}

export default FacilityModal;
const Cover = styled.div`
    width: 47%;
    background: #ffffff;
    box-shadow: -4px 4px 42px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    position: absolute;
    z-index: 10000000;
    top: 47px;
    right: 0;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    display: none;
    &.active {
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 63%;
    }
`;
const Container = styled.div`
    width: 100%;
    height: 150px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4ca473;
    width: 100%;
    height: 45px;
    padding: 12px;
    border-bottom: 1px solid #e7e7e7;
    color: #6b6b6b;
    font-family: "gordita_regular";
    cursor: pointer;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
    &:hover {
        background: #e4f8ed;
        color: #4ca473;
    }
`;
