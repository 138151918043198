import React from "react";
import styled from "styled-components";
import Fb from "../../assets/images/f.png";
import Watsapp from "../../assets/images/w.png";
import Clip from "../../assets/images/clip.png";
import { Link } from "react-router-dom";

function ShareModal({ isShare }) {
    return (
        <Container isShare={isShare}>
            <Title>Share this on</Title>
            <SocialMedias>
                <Media
                    href="http://www.facebook.com/sharer/sharer.php?u=https://ekerala.org/moment/talrop-conducted-an-orientation-program-for-the-students-of-gmlp-school-pathapiriyam/"
                    target="blank"
                >
                    <Image src={Fb} alt="fb" />
                </Media>
                <Media
                    href="whatsapp://send?text=https://ekerala.org/moment/talrop-conducted-an-orientation-program-for-the-students-of-gmlp-school-pathapiriyam/"
                    target="blank"
                >
                    <Image src={Watsapp} alt="Watsapp" />
                </Media>
                <Media
                    href="/moment/talrop-conducted-an-orientation-program-for-the-students-of-gmlp-school-pathapiriyam/"
                    target="blank"
                >
                    <Image src={Clip} alt="Clip" />
                </Media>
            </SocialMedias>
        </Container>
    );
}
const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 15px 20px;
    border-radius: 5px;
    width: 35%;
    display: ${({ isShare }) => (isShare ? "block" : "none")};
`;
const Title = styled.h4`
    font-size: 16px;
    margin-bottom: 15px;
`;
const SocialMedias = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    width: 70%;
    margin: 0 auto;
`;
const Media = styled.a`
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
`;
const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

export default ShareModal;
