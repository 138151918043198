import Img from "../../assets/images/teaching.jpg";
import Img1 from "../../assets/images/eventimg.jpeg";

export const ImageData = [
    {
        id: 1,
        src: Img,
    },
    {
        id: 2,
        src: Img1,
    },
    {
        id: 3,
        src: Img,
    },
    {
        id: 4,
        src: Img1,
    },
    {
        id: 5,
        src: Img,
    },
];
