import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function EventCard({ item }) {
    return (
        <CardContainer key={item.id}>
            <EventName>{item.name}</EventName>
            <ImageBox>
                <Image src={item.image} alt="event-image" />
            </ImageBox>
            <BottomBox>
                <Date>{item.date}</Date>
                <Location>{item.location}</Location>
                <Title>{item.title}</Title>
                <SubTitle>{item.subtitle}</SubTitle>
                <Button to="/moment-single-page">View</Button>
            </BottomBox>
        </CardContainer>
    );
}

const CardContainer = styled.div`
    position: relative;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%), 0 0 10px rgb(0 0 0 / 10%);
`;
const ImageBox = styled.div`
    width: 100%;
`;
const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
`;
const BottomBox = styled.div`
    padding: 30px;
    @media all and (max-width: 1280px) {
        padding: 20px;
    }
`;
const EventName = styled.h3`
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    font-size: 16px;
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;

const Date = styled.p`
    font-size: 12px;
    margin-bottom: 15px;
`;
const Location = styled.h4`
    font-size: 15px;
    margin-bottom: 15px;
    color: #28cc8b;
`;
const Title = styled.h3`
    font-size: 18px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
`;
const SubTitle = styled.h3`
    font-size: 16px;
    margin-bottom: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    line-height: 1.6;
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;
const Button = styled(Link)`
    padding: 6px 35px;
    border: 1px solid #28cc8b;
    color: #28cc8b;
    font-size: 14px;
    border-radius: 32px;
    cursor: pointer;
`;
export default EventCard;
