import React, { useState } from "react";
import styled from "styled-components";
import AddFacilities from "./modal/AddFacilities";
import FacilityModal from "./modal/FacilityModal";
import DateModal from "./modal/DateModal";
import moment from "moment";
import useClickOutside from "../hooks/useClickOutside";
import OttpModal from "./modal/OtpModal";
import { Link } from "react-router-dom";
import VenueImageSection from "../VenueImageSection";
import { useEffect } from "react";

function VenueRegisterForm() {
    // otp modal
    const [isOtp, setOtp] = useState(false);
    const [otpVal, setOtpVal] = useState("");
    const [phone, setPhone] = useState("");

    const [dateModal, setDateModal] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [usedDates, setUsedDates] = useState([]);
    const [placeModal, setPlaceModal] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState({
        name: "Select your district",
    });
    const [district, setDistrict] = useState([
        { id: 1, name: "Thiruvananthapuram" },
        { id: 2, name: "Kollam" },
        { id: 3, name: "Pathanamthitta" },
        { id: 4, name: "Alappuzha" },
        { id: 5, name: "Kottayam" },
        { id: 6, name: " Idukki" },
        { id: 7, name: "Eranakulam" },
        { id: 8, name: "Thrissur" },
        { id: 9, name: "Palakkad" },
        { id: 10, name: "Malappuram" },
        { id: 11, name: "Kozhikode" },
        { id: 12, name: "Wayanad" },
        { id: 13, name: "Kannur" },
        { id: 14, name: "Kasaragod" },
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const parentRef = document.getElementById("district-parent");
    const districtModalRef = useClickOutside(() => {
        setPlaceModal(false);
    }, parentRef);

    const deleteItem = (item) => {
        setSelectedItem(selectedItem.filter((_item) => _item.id !== item.id));
        setSelectedItems(selectedItems.filter((_item) => _item.id !== item.id));
    };
    const deleteDate = (item) => {
        setSelectedDates(selectedDates.filter((_item) => _item.id !== item.id));
    };
    const [isUpload, setUpload] = useState(false);
    console.log(isUpload, "ekerala");
    return (
        <>
            {isModal && (
                <AddFacilities
                    isModal={isModal}
                    setIsModal={setIsModal}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                />
            )}
            <OttpModal
                isOtp={isOtp}
                setOtp={setOtp}
                phone={phone}
                setPhone={setPhone}
                otpVal={otpVal}
                setOtpVal={setOtpVal}
                isUpload={isUpload}
                setUpload={setUpload}
            />
            {isUpload && <VenueImageSection isUpload={isUpload} setUpload={setUpload} />}
            {dateModal && (
                <DateModal
                    setSelectedDates={setSelectedDates}
                    dateModal={dateModal}
                    setDateModal={setDateModal}
                    usedDates={usedDates}
                    setUsedDates={setUsedDates}
                />
            )}
            <Container id="venue-register">
                <Section className="wrapper">
                    <Heading>Register Your venue</Heading>
                    <Description>Application Form</Description>
                    <Round>
                        <Green>
                            <span>01</span>
                        </Green>
                        <Color>
                            <span className="second">02</span>
                        </Color>
                    </Round>
                    <Contents>
                        <TopSection>
                            <NameSection>
                                <Label>Venue name *</Label>
                                <Input type="text" placeholder="Enter venue name" />
                            </NameSection>
                            <NameSection>
                                <Label> Name of the contact person *</Label>
                                <Input type="text" placeholder="Enter your name" />
                            </NameSection>
                            <NameSection>
                                <Label>Phone number *</Label>
                                <Div>
                                    <Country>
                                        <img
                                            src={require("../../assets/images/india.png")}
                                            alt="country"
                                        />
                                    </Country>
                                    <Span>+91</Span>
                                    <Input
                                        className="number"
                                        type="number"
                                        placeholder="Enter phone number "
                                    />
                                </Div>
                            </NameSection>
                            <NameSection>
                                <Label>Email address *</Label>
                                <Input type="email" placeholder="abc@sample.com" />
                            </NameSection>
                            <Location>
                                <Label>Map location *</Label>
                                <Input
                                    className="location"
                                    type="text"
                                    placeholder="Paste your map link"
                                />
                            </Location>
                            <NameSection>
                                <Label>Place *</Label>
                                <Input type="text" placeholder="Enter your place" />
                            </NameSection>
                            <NameSection>
                                <Label>District *</Label>
                                <DistrictBox
                                    id="district-parent"
                                    onClick={(e) => setPlaceModal(!placeModal)}
                                >
                                    <DistrictName>{selectedDistrict.name}</DistrictName>
                                    <ArrowContainer>
                                        <ArrowIcons
                                            src={
                                                require("../../assets/images/drop-arrow.svg")
                                                    .default
                                            }
                                            alt="next"
                                        />
                                    </ArrowContainer>
                                </DistrictBox>
                                <DistrictCover
                                    ref={districtModalRef}
                                    className={placeModal ? "active" : ""}
                                >
                                    <DistrictModal>
                                        {district?.map((item) => (
                                            <DistrictHeading
                                                key={item.id}
                                                onClick={(e) => {
                                                    setSelectedDistrict(item);
                                                    setPlaceModal(false);
                                                }}
                                            >
                                                {item.name}
                                            </DistrictHeading>
                                        ))}
                                    </DistrictModal>
                                </DistrictCover>
                            </NameSection>
                            <DateBox>
                                <Label>Set Date & Available Time*</Label>
                                <SubDate>
                                    {selectedDates.map((item) => (
                                        <SingleDate>
                                            {moment(item.date).format("DD-MM-YYYY")},{item.time}
                                            <CloseContainers onClick={(e) => deleteDate(item)}>
                                                <CloseIcons
                                                    src={
                                                        require("../../assets/images/close-icon.svg")
                                                            .default
                                                    }
                                                    alt="close"
                                                />
                                            </CloseContainers>
                                        </SingleDate>
                                    ))}
                                    <DateButton onClick={() => setDateModal(true)}>
                                        <PlusIcon
                                            src={require("../../assets/images/plus.svg").default}
                                            alt="next"
                                            className="add"
                                        />
                                        Add
                                    </DateButton>
                                </SubDate>
                            </DateBox>
                            <Location>
                                <Label>Facilities *</Label>
                                <SubLocation onClick={() => setIsModal(true)}>
                                    {selectedItem.length === 0 ? (
                                        <FacilityDiv>Add your facilities</FacilityDiv>
                                    ) : (
                                        <>
                                            {selectedItem.map((item) => (
                                                <FacilityBox>
                                                    <FacilityName>
                                                        <CloseContainer
                                                            onClick={() => deleteItem(item)}
                                                        >
                                                            <CloseIcon
                                                                src={
                                                                    require("../../assets/images/close-icon.svg")
                                                                        .default
                                                                }
                                                                alt="close"
                                                            />
                                                        </CloseContainer>
                                                        {item.name},{" "}
                                                    </FacilityName>
                                                    <ValueBox>{item?.value}</ValueBox>
                                                </FacilityBox>
                                            ))}
                                        </>
                                    )}

                                    <FacilityImg>
                                        <Img
                                            className="add"
                                            src={require("../../assets/images/addicon.svg").default}
                                            alt="add"
                                        />
                                    </FacilityImg>
                                </SubLocation>
                            </Location>
                            <Location>
                                <Label>Facilities note</Label>
                                <textarea placeholder="Type here" />
                            </Location>
                        </TopSection>
                        <NextButton onClick={() => setOtp(!isOtp)}>
                            Next
                            <Arrow
                                src={require("../../assets/images/next.svg").default}
                                alt="next"
                            />
                        </NextButton>
                    </Contents>
                </Section>
            </Container>
        </>
    );
}

export default VenueRegisterForm;
const Container = styled.div`
    padding: 50px 0;
    background: #f9f9f9;
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const Heading = styled.h1`
    color: #0e9f6a;
    font-family: "gordita_medium";
    font-size: 22px;
    text-align: center;
`;
const Description = styled.p`
    color: #2d2d2d;
    font-size: 16px;
`;
const Round = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    margin-top: 20px;
`;
const Green = styled.div`
    width: 40px;
    border: 3px solid #0e9f6a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
    position: relative;
    span {
        color: #494a4a;
        font-family: "gordita_medium";
    }
    &::after {
        content: "";
        width: 25px;
        height: 1px;
        background-color: #494a4a;
        position: absolute;
        right: -83%;
        top: 50%;
    }
`;
const Color = styled.div`
    width: 35px;
    border: 1px solid rgba(14, 159, 106, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    max-width: 35px;
    height: 35px;
    max-height: 35px;
    span.second {
        color: #494a4a;
        font-size: 14px;
    }
`;

const Contents = styled.div`
    width: 75%;
    margin: 50px auto 0;
    @media all and (max-width: 1080px) {
        width: 85%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const TopSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;
const NameSection = styled.div`
    margin-bottom: 25px;
    height: 85px;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const Div = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 8px;
    padding: 9px 16px;
    font-size: 16px;
    @media all and (max-width: 980px) {
        padding: 9px;
    }
`;
const Country = styled.div`
    width: 32px;
    height: 30px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        display: block;
        height: 100%;
    }
`;
const FacilityDiv = styled.div``;
const ArrowContainer = styled.div`
    width: 6%;
    cursor: pointer;
    @media all and (max-width: 980px) {
        width: 4%;
    }
    @media all and (max-width: 480px) {
        width: 6%;
    }
`;
const ArrowIcons = styled.img`
    width: 100%;
    display: block;
`;

const Span = styled.span`
    @media all and (max-width: 980px) {
        margin: 0 5px !important;
    }
`;
const Label = styled.label`
    color: #6b6b6b;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-bottom: 5px;
`;
const DistrictName = styled.div``;
const DistrictBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 13px;
    font-size: 16px;
`;
const DistrictCover = styled.div`
    display: none;
    width: 412px;
    padding: 5px 0;
    background: #ffff;
    border-radius: 4px;
    z-index: 1000;
    &.active {
        display: block;
    }
`;
const DistrictModal = styled.div`
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;
const DistrictHeading = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 55px;
    padding: 0 25px;
    border-bottom: 1px solid #e7e7e7;
    color: #6b6b6b;
    font-family: "gordita_regular";
    cursor: pointer;
    &:hover {
        background: #e4f8ed;

        color: #4ca473;
    }
`;
const Input = styled.input`
    background: #ffffff !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    &::placeholder {
        color: #9f9f9f;
    }
    &.time {
        border: none !important;
        width: 74%;
    }
    &.facilities {
        border: none !important;
        padding: 0 !important;
        width: 100%;
    }
    &.location {
        padding: 0 !important;
        width: 100%;
        background: #ffffff !important;
        border: 2px solid #d9d9d9 !important;
        border-radius: 8px;
        padding: 12px !important;
        font-size: 16px;
    }
    &.number {
        border: none !important;
        padding: 0 !important;
        width: 74%;
    }
`;
const NextButton = styled.div`
    cursor: pointer;
    color: #fff;
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    width: 190px;
    background-color: #1ec569;
    height: 50px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Arrow = styled.img`
    display: block;
    width: 15px;
    margin-left: 10px;
`;
const DateBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    width: 100%;
`;
const SubDate = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
`;
const SingleDate = styled.div`
    display: flex;
    margin-bottom: 10px;
    background-color: #eeeeee;
    width: 230px;
    margin-right: 10px;
    padding: 15px;
    border: 0.5px solid #d4d4d4;
    border-radius: 8px;
    position: relative;
`;
const DateButton = styled.div`
    cursor: pointer;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 8px;
    width: 90px;
    background-color: #1ec569;
    height: 40px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
`;
const PlusIcon = styled.img`
    margin-right: 10px;
    display: block;
    width: 15px;
`;

const Location = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    width: 100%;

    textarea {
        padding: 10px;
        background-color: #fff;
        height: 100px;
        padding: 12px;
        border: 2px solid #d9d9d9;
        border-radius: 8px;
        resize: none;
    }
`;

const FacilityBox = styled.div`
    display: flex;
    margin-bottom: 10px;
    background-color: #eeeeee;
    margin-right: 10px;
    padding: 15px;
    border: 0.5px solid #d4d4d4;
    border-radius: 8px;
    position: relative;
`;
const FacilityName = styled.div``;
const ValueBox = styled.div`
    color: #1ec569;
`;
const FacilityImg = styled.div`
    position: absolute;
    width: 3%;
    right: 12px;
    @media all and (max-width: 640px) {
        width: 5%;
    }
    @media all and (max-width: 360px) {
        width: 6%;
    }
`;
const Img = styled.img`
    width: 100%;
    display: block;
    cursor: pointer;
`;
const CloseContainer = styled.div`
    position: absolute;
    right: 4px;
    width: 20px;
    cursor: pointer;
    top: -7px;
    right: -5px;
    border-radius: 10px;
    border-radius: 2px solid #000;
    padding: 5px;
    background-color: #fff;
    background: #ffffff;
    border: 1px dashed #afafaf;
`;
const CloseIcons = styled.img`
    width: 100%;
    display: block;
`;
const CloseContainers = styled.div`
    position: absolute;
    right: 4px;
    width: 20px;
    cursor: pointer;
    top: -7px;
    right: -5px;
    border-radius: 10px;
    border-radius: 2px solid #000;
    padding: 5px;
    background-color: #fff;
    background: #ffffff;
    border: 1px dashed #afafaf;
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const SubLocation = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
`;
