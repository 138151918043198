import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function RegisterCard({ item }) {
    return (
        <Container key={item.id}>
            <ImgContainer>
                <Img src={item.image} alt="image" />
            </ImgContainer>
            <Register>{item.register}</Register>
            <Button to={item.path}>Register Now</Button>
        </Container>
    );
}

export default RegisterCard;
const Container = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 80px 50px;
    align-items: center;
    &:hover {
        background-color: #f8f8f8;
    }
    @media all and (max-width: 1080px) {
        padding: 60px 50px;
    }
    @media all and (max-width: 768px) {
        padding: 50px 30px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const ImgContainer = styled.div`
    width: 320px;
    margin: 0 auto;
    @media all and (max-width: 980px) {
        width: 250px;
    }
    @media all and (max-width: 768px) {
        width: 227px;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Img = styled.img`
    width: 100%;
    display: block;
`;
const Register = styled.h3`
    margin-top: 20px;
    font-family: "gordita_regular";
    color: #000000;
    font-size: 16px;
`;
const Button = styled(Link)`
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    width: 150px;
    background-color: #1ec569;
    height: 55px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
    margin: 30px 0 0;
    cursor: pointer;
    @media all and (max-width: 768px) {
        height: 45px;
        font-size: 15px;
    }
`;
