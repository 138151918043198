import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from "react";

function DateModal({ dateModal, setDateModal, setSelectedDates, setUsedDates, usedDates }) {
    const [modaltime, setModaltime] = useState(false);
    const [isError, setError] = useState(false);

    const [selectedDate, setSelectedDate] = useState({
        date: "",
        time: "Forenoon",
        id: "",
    });

    const submitHandler = () => {
        if (selectedDate.date !== "") {
            let currentSelectedDate = selectedDate;
            currentSelectedDate.id = new Date();
            setUsedDates([...usedDates, currentSelectedDate.date]);
            setSelectedDates((prev) => [...prev, currentSelectedDate]);
            setDateModal(false);
        } else {
            setError(true);
        }
    };

    const calenderRef = useRef();
    const minDate = new Date();
    const overlayRef = useRef(null);

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    return (
        <>
            {dateModal && (
                <>
                    <Container className={dateModal ? "active" : ""}>
                        <Overlay ref={overlayRef} onClick={() => setDateModal(false)}>
                            <Content onClick={(e) => e.stopPropagation()}>
                                <Top>
                                    {" "}
                                    <Heading>Add Date & Available Time</Heading>
                                    <FacilityBox></FacilityBox>
                                    <CloseContainer onClick={() => setDateModal(false)}>
                                        <CloseIcon
                                            src={
                                                require("../../../assets/images/close-icon.svg")
                                                    .default
                                            }
                                            alt="close"
                                        />
                                    </CloseContainer>
                                </Top>
                                <Bottom>
                                    <NameSection>
                                        <Label>Date*</Label>
                                        <CalenderBox>
                                            <DatePicker
                                                ref={calenderRef}
                                                selected={selectedDate.date}
                                                onChange={(_selectedDate) => {
                                                    setSelectedDate({
                                                        ...selectedDate,
                                                        date: _selectedDate,
                                                    });
                                                }}
                                                dateFormat={"dd/MM/yyyy"}
                                                placeholderText={"DD / MM / YY"}
                                                minDate={minDate}
                                                excludeDates={usedDates}
                                            />
                                            <CalenderContainer>
                                                <CalenderIcon
                                                    onClick={(e) => {
                                                        if (!calenderRef.current.state.open) {
                                                            calenderRef.current.setOpen(true);
                                                        }
                                                    }}
                                                    src={
                                                        require("../../../assets/images/calendar.svg")
                                                            .default
                                                    }
                                                    alt="calender"
                                                />
                                            </CalenderContainer>
                                        </CalenderBox>
                                        {isError && <ErrorMessage>*Input your Date</ErrorMessage>}
                                    </NameSection>
                                    <NameSection>
                                        <Label>Available Time*</Label>
                                        <TimeBox>
                                            {selectedDate.time}
                                            <ArrowContainer
                                                onClick={() => {
                                                    setModaltime(!modaltime);
                                                }}
                                            >
                                                <ArrowIcon
                                                    src={
                                                        require("../../../assets/images/Arrowdown.svg")
                                                            .default
                                                    }
                                                    alt="calender"
                                                />
                                            </ArrowContainer>
                                        </TimeBox>
                                        <TimeCover className={modaltime ? "active" : ""}>
                                            <TimeModal>
                                                <TimeDiv
                                                    onClick={() => {
                                                        // setData("Fornoon");
                                                        setSelectedDate({
                                                            ...selectedDate,
                                                            time: "Forenoon",
                                                        });
                                                        setModaltime(false);
                                                    }}
                                                >
                                                    Forenoon
                                                </TimeDiv>
                                                <TimeDiv
                                                    onClick={() => {
                                                        // setData("Afternoon");
                                                        setModaltime(false);
                                                        setSelectedDate({
                                                            ...selectedDate,
                                                            time: "Afternoon",
                                                        });
                                                    }}
                                                >
                                                    Afternoon
                                                </TimeDiv>
                                            </TimeModal>
                                        </TimeCover>
                                        {/* <ErrorMessage>*Input available time</ErrorMessage> */}
                                    </NameSection>
                                </Bottom>
                                <SubmitButton onClick={submitHandler}>Submit</SubmitButton>
                            </Content>
                        </Overlay>
                    </Container>
                </>
            )}
        </>
    );
}

export default DateModal;
const FacilityBox = styled.div`
    margin-bottom: 10px;
`;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    &.active {
        display: block;
    }
`;
const Overlay = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Content = styled.div`
    width: 700px;
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
    position: relative;
    overflow-x: scroll;
    max-height: 95vh;
    @media all and (max-width: 1080px) {
        width: 650px;
    }
    @media all and (max-width: 980px) {
        width: 600px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 450px;
    }
    @media all and (max-width: 480px) {
        width: 345px;
        padding: 20px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Heading = styled.h2`
    font-family: "gordita_medium";
    font-size: 20px;
    color: #2d2d2d;
    @media all and (max-width: 360px) {
        font-size: 17px;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #e7e7e7;
`;
const CloseContainer = styled.div`
    width: 15px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const Bottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 40px;
    @media all and (max-width: 360px) {
        padding-top: 28px;
    }
`;
const NameSection = styled.div`
    position: relative;
    margin-bottom: 25px;
    height: 85px;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Label = styled.label`
    color: #6b6b6b;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-bottom: 5px;
`;

const CalenderBox = styled.div`
    display: flex;
    justify-content: space-between;
    background: #ffffff !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 8px;
    padding: 11px;
    font-size: 16px;
    div.react-datepicker-wrapper > div {
        width: 100% !important;
        border: none !important;
    }
    .react-time-picker {
        box-sizing: border-box;
        border: none !important;
        width: 100% !important;
        display: flex;
        outline: none !important;
    }
    .react-time-picker__button:enabled {
        cursor: pointer;
        display: none !important;
    }
    .react-time-picker__wrapper {
        border: none !important;
    }
    .react-time-picker_inputGroup_input {
        cursor: pointer;
    }
    .react-time-picker_inputGroup_divider {
        margin-right: 6px !important;
    }

    .react-time-picker__clock {
        inset: 37px -11px 13px -6px !important;
    }
    .react-time-picker {
        @media all and (max-width: 640px) {
            height: 28px !important;
        }
    }
`;
const CalenderContainer = styled.div`
    width: 9%;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    @media all and (max-width: 640px) {
        width: 8%;
    }
`;
const CalenderIcon = styled.img`
    width: 100%;
    display: block;
`;
const TimeBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 8px;
    font-size: 16px;
    padding: 12px;
`;

const ArrowContainer = styled.div`
    cursor: pointer;
    width: 9%;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 640px) {
        width: 8%;
    }
`;
const ArrowIcon = styled.img`
    width: 100%;
    display: block;
`;

const TimeCover = styled.div`
    display: none;
    width: 298px;
    padding: 5px 0;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    top: 86px;
    &.active {
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 305px;
    }
    @media all and (max-width: 360px) {
        width: 260px;
    }
`;
const TimeModal = styled.div``;
const TimeDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 55px;
    padding: 0 25px;
    border-bottom: 1px solid #e7e7e7;
    color: #6b6b6b;
    font-family: "gordita_regular";
    cursor: pointer;
    &:hover {
        background: #e4f8ed;

        color: #4ca473;
    }
`;
const SubmitButton = styled.div`
    cursor: pointer;
    color: #fff;
    display: flex;
    margin-left: auto;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    width: 190px;
    background-color: #1ec569;
    height: 50px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 480px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        margin-top: 10px;
    }
`;
const ErrorMessage = styled.p`
    margin-top: 10px;
    font-size: 15px;
    color: #c43025;
    position: absolute;
    bottom: -34px;
`;
