import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavData } from "../datas/NavData";
import Hamburger from "../../assets/images/Handburg.svg";

function HamburgerMenu({ isMenuModal, setIsMenuModal }) {
    return (
        <MainContainer className={isMenuModal ? "active" : ""}>
            <InnerContainer>
                <Overlay onClick={() => setIsMenuModal(false)}></Overlay>
                <MenuBox>
                    <TopBox>
                        <Cancel onClick={() => setIsMenuModal(false)}>
                            <CancelImg src={Hamburger} alt="close" />
                        </Cancel>
                    </TopBox>
                    <BottomBox>
                        {NavData.map((item) => (
                            <Item to={item.path}>{item.name}</Item>
                        ))}
                    </BottomBox>
                </MenuBox>
            </InnerContainer>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    transform: translateX(100%);
    transition: all 0.4s ease-in;
    opacity: 0;
    @media all and (max-width: 768px) {
        &.active {
            transform: translateX(0);
            transition: all 0.4s ease-in;
            opacity: 1;
        }
    }
`;
const InnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;
const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
`;
const MenuBox = styled.div`
    width: 40%;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    @media all and (max-width: 640px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 55%;
    }
    @media all and (max-width: 400px) {
        width: 60%;
    }
    @media all and (max-width: 360px) {
        width: 65%;
    }
`;
const TopBox = styled.div`
    padding: 15px 15px 0 15px;
    display: flex;
    justify-content: flex-end;
`;
const Cancel = styled.div`
    width: 30px;
`;
const CancelImg = styled.img`
    display: block;
    width: 100%;
`;
const BottomBox = styled.div``;
const Item = styled(Link)`
    display: block;
    font-size: 14px;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
    color: #000;
    cursor: pointer;
    font-family: "gordita_medium";
`;
export default HamburgerMenu;
