import React from "react";
import styled from "styled-components";

function Heading({ title, subtitle, home }) {
    return (
        <Box home={home}>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
        </Box>
    );
}

const Box = styled.div`
    width: ${({ home }) => (home ? "50%" : "100%")};
    display: flex;
    align-items: baseline;
    @media all and (max-width: 700px) {
        width: 100%;
    }
`;
const Title = styled.h2`
    font-size: 34px;
    position: relative;
    color: #555;
    margin-right: 10px;
    &::after {
        content: "";
        width: 75%;
        height: 5px;
        background-color: #28cc8b;
        position: absolute;
        left: 0;
        bottom: -5px;
    }
    @media all and (max-width: 1080px) {
        font-size: 32px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media all and (max-width: 768px) {
        font-size: 28px;
    }
    @media all and (max-width: 640px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const SubTitle = styled.h4`
    color: #555;
    font-size: 18px;
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
export default Heading;
