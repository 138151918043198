import React from "react";
import styled from "styled-components";
import { MomentData } from "../../datas/MomentData";
import EventCard from "../home/EventCard";

function SimilarMoments() {
    const Length = 2;
    return (
        <MainContainer>
            <Heading>Similar Moments</Heading>
            <Container>
                {MomentData.map((item, i) =>
                    i <= Length ? <EventCard item={item} /> : null
                )}
            </Container>
        </MainContainer>
    );
}
const MainContainer = styled.div``;
const Heading = styled.div`
    font-size: 24px;
    margin-bottom: 30px;
    @media all and (max-width: 640px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 40px;
    @media all and (max-width: 1200px) {
        grid-column-gap: 40px;
    }
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 50px;
    }
    @media all and (max-width: 768px) {
        grid-column-gap: 40px;
    }
    @media all and (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

export default SimilarMoments;
