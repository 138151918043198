import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MomentGallery from "../includes/moment-single-page/MomentGallery";
import MomentSinglePageSpotlight from "../includes/moment-single-page/MomentSinglePageSpotlight";
import SimilarMoments from "../includes/moment-single-page/SimilarMoments";

function MomentSinglePage() {
    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <MainContainer>
            <div className="wrapper">
                <MomentSinglePageSpotlight />
                <MomentGallery isModal={isModal} setIsModal={setIsModal} />
                <SimilarMoments />
            </div>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    padding: 60px 0;
    position: relative;
`;
export default MomentSinglePage;
