import React from "react";
import styled from "styled-components";
import ProjectCard from "./ProjectCard";

function AboutProject() {
    const ProjectData = [
        {
            id: 1,
            name: "Why this mission?",
            description:
                "To attain global best practices and become a leading digital Kerala with high performance environment",
            image: "",
            alt: "image",
        },
        {
            id: 2,
            name: "Who all are behind?",
            description:
                "Talrop, social organisations and young people from campus.",
            image: "",
            alt: "image",
        },
        {
            id: 3,
            name: "What are the benefits?",
            description:
                "Ensures learning, team management and creativity to overcome challenges and to attain goals",
            image: "",
            alt: "image",
        },
    ];
    return (
        <Container>
            <div className="wrapper">
                <Heading>About this project</Heading>
                <Content>
                    {ProjectData.map((item) => (
                        <ProjectCard item={item} />
                    ))}
                </Content>
            </div>
        </Container>
    );
}

export default AboutProject;
const Container = styled.div`
    width: 100%;
    padding: 50px 0;
    background: #f8f8f8;
    position: relative;
`;
const Heading = styled.h3`
    text-align: center;
    font-family: "gordita_medium";
    color: #333;
    font-size: 28px;
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Content = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 769px) {
        flex-direction: column;
    }
`;
