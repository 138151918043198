import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dance from "../../assets/images/dance.jpg";
import Pooram from "../../assets/images/pooram.jpg";
import Heading from "../includes/Heading";

function AboutKerala() {
    const SliderData = [
        {
            id: 1,
            image: Dance,
        },
        {
            id: 1,
            image: Pooram,
        },
        {
            id: 1,
            image: Dance,
        },
        {
            id: 1,
            image: Pooram,
        },
    ];
    const [current, setCurrent] = useState(0);
    const length = SliderData.length;
    const timeout = useRef(null);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current === length - 1 ? 0 : current + 1);
        };

        timeout.current = setTimeout(nextSlide, 2500);
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [current, length]);
    if (!Array.isArray(SliderData) || SliderData.length <= 0) {
        return null;
    }

    return (
        <MainSection>
            <TopSection>
                <Heading title={"Kerala"} subtitle={"Gods Own Country"} />
            </TopSection>
            <BottomContainer>
                {" "}
                <LeftContainer>
                    <p>
                        Kerala is one of the most progressive states in terms of
                        social welfare, quality of life and literacy rate. It is
                        also known for its cultural heritage and its
                        environmental beauty. Besides these, the state upholds a
                        firm administration, i.e. from the educational
                        institution to social organizations, Kerala stands out
                        as the only state to maintain its quality and
                        unbreakable traditions. The state is an example of unity
                        in diversity. People from different states travel to
                        Kerala for tourism, employment and what not. It stands
                        out for its beauty and remarkable governance.
                    </p>
                </LeftContainer>
                <RightContainer>
                    {SliderData.map((item, i) => (
                        <SliderBox key={i}>
                            {i === current && (
                                <Slide>
                                    <ImageBox>
                                        <Image
                                            src={item.image}
                                            alt="kerala-image"
                                        />
                                    </ImageBox>
                                </Slide>
                            )}
                        </SliderBox>
                    ))}
                </RightContainer>
            </BottomContainer>
        </MainSection>
    );
}
const MainSection = styled.div`
    padding: 50px 0;
    @media all and (max-width: 768px) {
        padding: 50px 0 40px 0;
    }
    @media all and (max-width: 640px) {
        padding: 40px 0 30px 0;
    }
`;
const TopSection = styled.div`
    margin-bottom: 40px;
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1000px) {
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
`;
const LeftContainer = styled.div`
    width: 52%;
    & p {
        font-size: 16px;
        color: #606060;
        text-align: justify;
        @media all and (max-width: 1080px) {
            font-size: 15px;
        }
        @media all and (max-width: 1000px) {
            font-size: 16px;
        }
    }
    @media all and (max-width: 1000px) {
        width: 100%;
    }
`;
const RightContainer = styled.div`
    border-radius: 16px;
    overflow: hidden;
    width: 43%;
    @media all and (max-width: 1000px) {
        width: 90%;
        margin: 0 auto 40px;
    }
`;
const SliderBox = styled.div``;
const Slide = styled.div`
    display: block;
    width: 100%;
    height: 100%;

    transition: all ease-in 0.4s;
`;
const ImageBox = styled.div``;
const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
`;

export default AboutKerala;
