import React, { useEffect } from "react";
import styled from "styled-components";
import Search from "../../assets/images/search.png";
import EventCard from "../includes/home/EventCard";
import Heading from "../includes/Heading";
import { MomentData } from "../datas/MomentData";

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <MainContainer id="main">
            <div className="wrapper">
                <TopSection>
                    <Heading
                        title={"Moments"}
                        subtitle={"News & Events"}
                        home="true"
                    />
                    <RightBox>
                        <OuterInput>
                            <Input type="text" placeholder="Search.." />
                        </OuterInput>
                        <SearchIconBox>
                            <SearchIcon src={Search} alt="search-icon" />
                        </SearchIconBox>
                    </RightBox>
                </TopSection>
                <BottomContainer>
                    {MomentData.map((item) => (
                        <EventCard item={item} />
                    ))}
                </BottomContainer>
            </div>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 60px 0;
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media all and (max-width: 700px) {
        flex-wrap: wrap;
    }
`;
const RightBox = styled.form`
    display: inline-block;
    width: 25%;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e9e9e9;
    border: 1px solid #28cc8b;
    padding: 10px 15px;
    @media all and (max-width: 1280px) {
        width: 30%;
        padding: 8px 12px;
    }
    @media all and (max-width: 980px) {
        width: 35%;
    }
    @media all and (max-width: 700px) {
        width: 50%;
        margin-top: 20px;
    }
    @media all and (max-width: 480px) {
        width: 60%;
    }
    @media all and (max-width: 360px) {
        width: 80%;
    }
`;
const OuterInput = styled.div`
    padding-left: 50px;
    width: 85%;
`;
const Input = styled.input`
    width: 100%;
    font-size: 16px;
`;
const SearchIconBox = styled.button`
    width: 20px;
`;
const SearchIcon = styled.img`
    display: block;
    width: 100%;
`;
const BottomContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 40px;
    @media all and (max-width: 1200px) {
        grid-column-gap: 40px;
    }
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 50px;
    }
    @media all and (max-width: 768px) {
        grid-column-gap: 40px;
    }
    @media all and (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

export default Home;
