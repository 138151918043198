import React from "react";
import styled from "styled-components";
import { DistrictData } from "../datas/DistrictData";
function GodsOwnCountry() {
    return (
        <MainSection>
            <Heading>God's own Country</Heading>
            <ContentContainer>
                {DistrictData.map((item) => (
                    <Content key={item.id}>
                        <ImageBox>
                            <Image src={item.image} alt={item.district} />
                        </ImageBox>
                        <Name>{item.district}</Name>
                    </Content>
                ))}
            </ContentContainer>
        </MainSection>
    );
}
const MainSection = styled.div`
    padding: 50px 0;
`;
const Heading = styled.h3`
    font-size: 32px;
    text-align: center;
    margin-bottom: 40px;
    font-family: "gordita_medium";
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media all and (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 22px;
    }
`;
const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
    @media all and (max-width: 1080px) {
        grid-gap: 40px;
    }
    @media all and (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 640px) {
        grid-template-columns: 1fr;
    }
`;
const Content = styled.div`
    box-shadow: 5px 5px 5px #c8c8c8;
    border-radius: 14px;
    overflow: hidden;
`;
const ImageBox = styled.div`
    width: 100%;
    height: 250px;
`;
const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const Name = styled.div`
    font-size: 16px;
    text-align: center;
    color: #606060;
    padding: 10px;
`;
export default GodsOwnCountry;
