import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import seminar from "../../../assets/images/seminar.jpg";
import Teaching from "../../../assets/images/teaching.jpg";
import Heading from "../Heading";

function Spotlight() {
    const SliderData = [
        {
            id: 1,
            image: seminar,
        },
        {
            id: 1,
            image: Teaching,
        },
        {
            id: 1,
            image: seminar,
        },
        {
            id: 1,
            image: Teaching,
        },
    ];
    const [current, setCurrent] = useState(0);
    const length = SliderData.length;
    const timeout = useRef(null);
    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current === length - 1 ? 0 : current + 1);
        };

        timeout.current = setTimeout(nextSlide, 2500);
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [current, length]);
    if (!Array.isArray(SliderData) || SliderData.length <= 0) {
        return null;
    }
    return (
        <Container>
            <Section className="wrapper">
                <TopSection>
                    <Heading title="About us" subtitle="Who we are ?" />
                </TopSection>
                <Contents>
                    <LeftContainer>
                        {SliderData.map((item, i) => (
                            <SliderBox key={i}>
                                {i === current && (
                                    <Slide>
                                        <ImageBox>
                                            <Image
                                                src={item.image}
                                                alt="kerala-image"
                                            />
                                        </ImageBox>
                                    </Slide>
                                )}
                            </SliderBox>
                        ))}
                    </LeftContainer>
                    <RightContainer>
                        <Title>The Project</Title>
                        <Description>
                            Mission E-Kerala 2025 paves its way to the digital
                            world where every citizen will be able to use
                            technology as their medium to carry out their daily
                            tasks. Also, E-kerala aims to build a smart society
                            with smart citizens and individuals capable of
                            initiating technology startups and businesses.
                        </Description>
                    </RightContainer>
                </Contents>
            </Section>
        </Container>
    );
}

export default Spotlight;
const Container = styled.div`
    width: 100%;
    padding: 0px 0 50px;
    @media all and (max-width: 980px) {
        padding-bottom: 30px;
    }
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
`;
const TopSection = styled.div`
    margin-bottom: 40px;
`;
const Contents = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-column-gap: 80px; */
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;
const LeftContainer = styled.div`
    width: 43%;
    border-radius: 16px;
    overflow: hidden;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const SliderBox = styled.div``;
const Slide = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    transition: all ease-in 0.4s;
`;
const ImageBox = styled.div``;
const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
`;

const RightContainer = styled.div`
    width: 50%;
    @media all and (max-width: 980px) {
        width: 100%;
        margin-top: 30px;
    }
`;
const Title = styled.h3`
    margin-bottom: 20px;
    font-size: 25px;
    font-family: "gordita_regular";
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 768px) {
        font-size: 35px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Description = styled.p`
    font-size: 16px;
    color: #606060;
    text-align: justify;
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
