import React, { useState } from "react";
import styled from "styled-components";
import FacilityModal from "./FacilityModal";
import { useRef } from "react";
import useScroll from "../../hooks/useScroll";

function AddFacilities({
    isModal,
    setIsModal,
    selectedItem,
    setSelectedItem,
    selectedItems,
    setSelectedItems,
}) {
    console.log();
    const [isFacility, setIsFacility] = useState(false);

    // const [items, setItems] = useState(selectedItem);
    const overlayRef = useRef(null);

    const onChange = (e, item, placeholder) => {
        var _selectedItem = selectedItems.find((_item) => _item.id == item.id);
        if (placeholder === "yes") {
            var value = e.target.value.replace(/[^a-z]/gi, "");
        } else {
            var value = e.target.value.replace(/[^0-9]/gi, "");
        }

        _selectedItem["value"] = value;

        setSelectedItem((prev) => [...new Set(prev.concat(selectedItem))]);
    };

    const submitHandler = () => {
        setSelectedItem([...selectedItems]);
        setIsModal(false);
    };

    useScroll();

    return (
        <>
            {isModal && (
                <>
                    <Container className={isModal ? "active" : ""}>
                        <Overlay ref={overlayRef} onClick={() => setIsModal(false)}>
                            <Content onClick={(e) => e.stopPropagation()}>
                                <Top>
                                    {" "}
                                    <Heading>Add your Facilities</Heading>
                                    <CloseContainer onClick={() => setIsModal(false)}>
                                        <CloseIcon
                                            src={
                                                require("../../../assets/images/close-icon.svg")
                                                    .default
                                            }
                                            alt="close"
                                        />
                                    </CloseContainer>
                                </Top>
                                <Bottom>
                                    <Headings>
                                        <LabelLeft>Facilities*</LabelLeft>
                                        <LabelRight>Count*</LabelRight>
                                    </Headings>
                                    <Location>
                                        {selectedItems?.map((item) => (
                                            <InputCover>
                                                <InputSubLeft>
                                                    <Item>{item.name}</Item>
                                                </InputSubLeft>
                                                <InputSubRight>
                                                    <Input
                                                        className="value"
                                                        type="text"
                                                        placeholder={item?.placeholder}
                                                        value={item?.value}
                                                        onChange={(e) => {
                                                            onChange(e, item, item.placeholder);
                                                        }}
                                                    />
                                                </InputSubRight>
                                            </InputCover>
                                        ))}
                                        <ImgCover>
                                            <AddIconText>Add Your Facility</AddIconText>
                                            <Img
                                                onClick={() => setIsFacility(true)}
                                                className="add"
                                                src={
                                                    require("../../../assets/images/plusicon.svg")
                                                        .default
                                                }
                                                alt="add"
                                            />
                                            <FacilityModal
                                                isFacility={isFacility}
                                                setIsFacility={setIsFacility}
                                                setSelectedItems={setSelectedItems}
                                            />
                                        </ImgCover>
                                    </Location>
                                    <SubmitButton onClick={submitHandler}>Submit</SubmitButton>
                                </Bottom>
                            </Content>
                        </Overlay>
                    </Container>
                </>
            )}
        </>
    );
}

export default AddFacilities;
const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    &.active {
        display: block;
    }
`;
const Overlay = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Item = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 15px;
    height: 55px;
    margin: 5px;
    @media all and (max-width: 480px) {
        align-items: center;
        display: flex;
        padding: 6px;
        font-size: 16px;
    }
`;
const Content = styled.div`
    width: 700px;
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
    max-height: 90vh;
    overflow-y: scroll;
    position: relative;
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1080px) {
        width: 650px;
    }
    @media all and (max-width: 980px) {
        width: 600px;
    }
    @media all and (max-width: 768px) {
        width: 550px;
    }
    @media all and (max-width: 640px) {
        width: 450px;
    }
    @media all and (max-width: 480px) {
        width: 345px;
        padding: 17px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Heading = styled.h2`
    font-family: "gordita_medium";
    font-size: 20px;
    color: #2d2d2d;
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #e7e7e7;
`;
const CloseContainer = styled.div`
    width: 15px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const Headings = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Location = styled.div`
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-radius: 8px;
`;
const ImgCover = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    border: 2px dashed #d9d9d9;
    border-radius: 8px;
    margin-top: 15px;
`;
const AddIconText = styled.div``;

const Img = styled.img`
    width: 20px;
    cursor: pointer;
    @media all and (max-width: 360px) {
        width: 15px;
    }
`;
const LabelLeft = styled.label`
    color: #6b6b6b;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-bottom: 5px;
    width: 92%;
`;
const LabelRight = styled.label`
    color: #6b6b6b;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-bottom: 5px;
    width: 50%;
`;
const Input = styled.input`
    border: 1px solid #d9d9d9 !important;
    border-radius: 8px;
    padding: 15px;
    margin: 5px;
    height: 55px;
    width: 100%;
    &.value {
        font-size: 14px;
        color: #1ec569;
        font-family: "gordita_medium";
    }
`;
const Bottom = styled.div`
    padding: 30px 0 0;
`;
const InputSubLeft = styled.div`
    width: 70%;
`;
const InputSubRight = styled.div`
    width: 39%;
`;
const InputCover = styled.div`
    display: flex;
`;
const SubmitButton = styled.div`
    cursor: pointer;
    color: #fff;
    display: flex;
    margin-left: auto;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    width: 190px;
    background-color: #1ec569;
    height: 50px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
