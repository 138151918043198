import React, { useEffect } from "react";
import Spotlight from "../includes/about/Spotlight";
import styled from "styled-components";
import AboutProject from "../includes/about/AboutProject";
import Mission from "../includes/about/Mission";

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Container>
                <Spotlight />
                <AboutProject />
                <Mission />
            </Container>
        </>
    );
}

export default About;
const Container = styled.div`
    padding: 20px 0 40px;
`;
