import React from "react";
import styled from "styled-components";
import Bg from "../../../assets/images/ekerala-sprite.png";

function ProjectCard({ item }) {
    return (
        <>
            <Container key={item.id}>
                <Section>
                    <Heading>{item.name}</Heading>
                    <Description>{item.description}</Description>
                    <Span>
                        <Div></Div>
                    </Span>
                    <Round className="round">
                        <Arrow>
                            <ArrowIcon
                                src={
                                    require("../../../assets/images/arrow.svg")
                                        .default
                                }
                                alt="arrow"
                            />
                        </Arrow>
                    </Round>
                </Section>
            </Container>
        </>
    );
}

export default ProjectCard;
const Container = styled.div`
    float: left;
    width: 32.5%;
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%), 0 0 10px rgb(0 0 0 / 10%);
    position: relative;
    margin-right: 1%;
    &:last-child {
        & .round {
            display: none;
        }
    }
    @media all and (max-width: 1280px) {
        padding-bottom: 0;
    }
    @media all and (max-width: 980px) {
        padding: 33px 33px 0px 33px;
    }

    @media all and (max-width: 769px) {
        width: 100%;
        margin-bottom: 8px;
    }
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
`;
const Heading = styled.h4`
    font-family: "gordita_medium";
    font-size: 19px;
    margin-bottom: 16px;
    color: #333;
`;
const Description = styled.p`
    color: #989898;
    font-size: 16px;
    width: 80%;
    margin-bottom: 30px;
    height: 80px;
    @media all and (max-width: 1280px) {
        width: 85%;
        height: 118px;
    }
    @media all and (max-width: 1080px) {
        width: 88%;
        height: 155px;
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        height: 155px;
        font-size: 16px;
        margin-bottom: 0;
    }
    @media all and (max-width: 768px) {
        height: 75px;
    }
    @media all and (max-width: 480px) {
        height: 106px;
        font-size: 15px;
    }
`;
const Span = styled.div`
    width: 100%;
    height: 117px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background: url(${Bg}) no-repeat 206px -96px;
    @media all and (max-width: 1280px) {
        background: url(${Bg}) no-repeat 122px -96px;
    }
    @media all and (max-width: 1080px) {
        background: url(${Bg}) no-repeat 111px -96px;
    }
    @media all and (max-width: 980px) {
        background: url(${Bg}) no-repeat repeat -9px -103px;
        height: 90px;
        width: 53%;
        margin-left: auto;
    }
    @media all and (max-width: 768px) {
        background: url(${Bg}) no-repeat 209px -104px;
        height: 111px;
        width: 300px;
        margin-left: auto;
    }
    @media all and (max-width: 480px) {
        background: url(${Bg}) no-repeat 163px -104px;
        height: 111px;
        width: 260px;
    }
    @media all and (max-width: 360px) {
        background: url(${Bg}) no-repeat 101px -104px;
        height: 111px;
        width: 202px;
    }
`;
const Div = styled.div``;

const Round = styled.div`
    position: absolute;
    bottom: 150px;
    right: -40px;
    width: 70px;
    height: 70px;
    border: 10px solid #d2f3e6;
    font-size: 20px;
    border-radius: 50%;
    background-color: #28cc8b;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 1080px) {
        bottom: 185px;
    }
    @media all and (max-width: 980px) {
        bottom: 156px;
    }
    @media all and (max-width: 769px) {
        top: 94%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: rotate(90deg);
    }
`;
const Arrow = styled.div`
    width: 18px;
    color: #fff;
    margin: 16px auto;
`;
const ArrowIcon = styled.img`
    width: 100%;
    display: block;
`;
