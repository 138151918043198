import styled from "styled-components";
import Footer from "./includes/Footer";
import Header from "./includes/Header";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import useClickOutside from "./hooks/useClickOutside";
import { timers } from "jquery";

function VenueImageSection({ isUpload, setUpload }) {
    const location = useLocation();
    console.log(location.pathname);

    const [time, setTime] = useState([
        { id: 1, name: "Online" },
        { id: 2, name: "Offline" },
    ]);
    const [selectedTime, setSelectedTime] = useState({ id: 2, name: "Offline" });
    const [timeModal, setTimeModal] = useState(false);
    //
    const [placeModal, setPlaceModal] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState({
        name: "Select your district",
    });
    const [district, setDistrict] = useState([
        { id: 1, name: "Thiruvananthapuram" },
        { id: 2, name: "Kollam" },
        { id: 3, name: "Pathanamthitta" },
        { id: 4, name: "Alappuzha" },
        { id: 5, name: "Kottayam" },
        { id: 6, name: " Idukki" },
        { id: 7, name: "Eranakulam" },
        { id: 8, name: "Thrissur" },
        { id: 9, name: "Palakkad" },
        { id: 10, name: "Malappuram" },
        { id: 11, name: "Kozhikode" },
        { id: 12, name: "Wayanad" },
        { id: 13, name: "Kannur" },
        { id: 14, name: "Kasaragod" },
    ]);
    const parentRef = document.getElementById("district-parent");
    const districtModalRef = useClickOutside(() => {
        setPlaceModal(false);
    }, parentRef);

    const timeParent = document.getElementById("time-parent");
    const timeModalRef = useClickOutside(() => {
        setTimeModal(false);
    }, timeParent);

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: (acceptedFiles) => {
            setFiles([
                ...files,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        id: new Date(),
                    })
                ),
            ]);
        },
    });

    const deleteImage = (img) => {
        setFiles(files.filter((file) => file.id !== img.id));
    };

    const thumbs = files?.map((file) => (
        <SubList style={thumb} key={file.name}>
            <CloseContainer onClick={() => deleteImage(file)}>
                <CloseIcon src={require("../assets/images/close.svg").default} alt="close" />
            </CloseContainer>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </div>
        </SubList>
    ));
    useEffect(() => {
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);
    const [link, setLink] = useState([]);
    return (
        <>
            <MainContainer id="venue-image">
                <Header style={{ zIndex: 10000 }} />
                <TopSection className="wrapper">
                    {location.pathname === "/signup/venue-register" ? (
                        <>
                            <Heading>Register Your venue</Heading>
                            <Description>Application Form</Description>
                        </>
                    ) : (
                        <>
                            <Heading>Register as a faculty</Heading>
                            <Description>Upload Faculty photos and videos</Description>
                        </>
                    )}
                    <Round>
                        <Color>
                            <span>01</span>
                        </Color>
                        <Green>
                            <span className="second">02</span>
                        </Green>
                    </Round>
                </TopSection>{" "}
                <SubContainer>
                    <MainBox>
                        {location.pathname === "/signup/faculty-register" && (
                            <>
                                <TopBox>
                                    <NameSection>
                                        <Label>Place of Preference</Label>
                                        <DistrictBox
                                            id="district-parent"
                                            onClick={(e) => setPlaceModal(!placeModal)}
                                        >
                                            <DistrictName>{selectedDistrict.name}</DistrictName>
                                            <ArrowContainer>
                                                <ArrowIcons
                                                    src={
                                                        require("../assets/images/drop-arrow.svg")
                                                            .default
                                                    }
                                                    alt="next"
                                                />
                                            </ArrowContainer>
                                        </DistrictBox>
                                        <DistrictCover
                                            ref={districtModalRef}
                                            className={placeModal ? "active" : ""}
                                        >
                                            <DistrictModal>
                                                {district?.map((item) => (
                                                    <DistrictHeading
                                                        key={item.id}
                                                        onClick={(e) => {
                                                            setSelectedDistrict(item);
                                                            setPlaceModal(false);
                                                        }}
                                                    >
                                                        {item.name}
                                                    </DistrictHeading>
                                                ))}
                                            </DistrictModal>
                                        </DistrictCover>
                                    </NameSection>
                                    <NameSection>
                                        <Label>Mode of Session</Label>
                                        <TimeBox
                                            id="time-parent"
                                            onClick={(e) => setTimeModal(!timeModal)}
                                        >
                                            <TimeName>{selectedTime.name}</TimeName>
                                            <ArrowContainer>
                                                <ArrowIcons
                                                    src={
                                                        require("../assets/images/drop-arrow.svg")
                                                            .default
                                                    }
                                                    alt="next"
                                                />
                                            </ArrowContainer>
                                        </TimeBox>
                                        <TimeCover
                                            ref={timeModalRef}
                                            className={timeModal ? "active" : ""}
                                        >
                                            <TimeModal>
                                                {time?.map((item) => (
                                                    <TimeHeading
                                                        key={item.id}
                                                        onClick={(e) => {
                                                            setSelectedTime(item);
                                                            setTimeModal(false);
                                                        }}
                                                    >
                                                        {item.name}
                                                    </TimeHeading>
                                                ))}
                                            </TimeModal>
                                        </TimeCover>
                                    </NameSection>
                                </TopBox>
                            </>
                        )}

                        <UploadBox>
                            <ContentBox>
                                <section className="container">
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} />
                                        <FolderImgBox>
                                            <FolderImg
                                                src={
                                                    require("../assets/images/file-icon.svg")
                                                        .default
                                                }
                                            />
                                        </FolderImgBox>
                                        <Para>Drag and Drop photos or videos here.</Para>
                                        <BrowseHeading>Browse file</BrowseHeading>
                                    </div>
                                    {/* <aside style={thumbsContainer}></aside> */}
                                </section>
                            </ContentBox>
                        </UploadBox>
                        <MainList>{thumbs}</MainList>
                        <NameSection className="link">
                            <Label>Add Links</Label>
                            <LinkBox>
                                <Input type="text" placeholder="Type Link Here" />
                            </LinkBox>
                        </NameSection>
                        <SubmitButton>Submit</SubmitButton>
                    </MainBox>
                </SubContainer>
                <Footer />
            </MainContainer>
        </>
    );
}

export default VenueImageSection;

const MainContainer = styled.div`
    background: #ffff;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    height: 100vh;
`;
const TopSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const Heading = styled.h1`
    color: #0e9f6a;
    font-family: "gordita_medium";
    font-size: 22px;
    text-align: center;
`;
const Description = styled.p`
    color: #2d2d2d;
    font-size: 16px;
`;
const Round = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    margin-top: 20px;
`;
const Green = styled.div`
    width: 40px;
    border: 3px solid #0e9f6a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
    span {
        color: #494a4a;
        font-family: "gordita_medium";
    }
`;
const Color = styled.div`
    width: 35px;
    border: 1px solid rgba(14, 159, 106, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    max-width: 35px;
    height: 35px;
    max-height: 35px;
    position: relative;

    span.second {
        color: #494a4a;
        font-size: 14px;
    }
    &::after {
        content: "";
        width: 25px;
        height: 1px;
        background-color: #494a4a;
        position: absolute;
        right: -83%;
        top: 50%;
    }
`;
const MainBox = styled.div`
    width: 60%;
    @media all and (max-width: 1080px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 78%;
    }
    @media all and (max-width: 768px) {
        width: 98%;
    }
`;
const SubContainer = styled.div`
    padding: 20px 80px;
    display: flex;
    justify-content: center;
    @media all and (max-width: 480px) {
        padding: 20px 22px;
    }
`;
const UploadBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 70px;
    margin-top: 40px;
    display: flex;
    border: 1.5px dashed #afafaf;
    background: #fdfdfd;
    border-radius: 8px;
    @media all and (max-width: 640px) {
        padding: 60px;
    }
    @media all and (max-width: 480px) {
        padding: 45px;
    }
`;
const ContentBox = styled.div`
    text-align: center;
`;
const FolderImgBox = styled.div`
    cursor: pointer;
    width: 20%;
    margin: 0 auto;
`;
const FolderImg = styled.img`
    width: 100%;
    display: block;
`;
const Para = styled.p`
    cursor: pointer;
    color: #9f9f9f;
    font-size: 18px;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
`;
const BrowseHeading = styled.a`
    cursor: pointer;
    color: #1ec569;
    text-decoration: underline;
`;
const MainList = styled.ul`
    display: flex;
    margin-top: 25px;

    max-height: 150px;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const SubList = styled.li`
    position: relative;
    padding: 50px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    border: 1.5px dashed #afafaf;
    background: #fdfdfd;
    border-radius: 8px;
`;
const TopBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const NameSection = styled.div`
    position: relative;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
    &.link {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Label = styled.label`
    color: #6b6b6b;
    font-family: "gordita_regular";
    font-size: 13px;
    margin-bottom: 5px;
`;
const DistrictName = styled.div``;
const DistrictBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
`;
const DistrictCover = styled.div`
    position: absolute;
    top: 70px;
    display: none;
    width: 100%;
    overflow: hidden;
    padding: 5px 0;
    background: #ffff;
    border-radius: 4px;
    z-index: 1000;
    &.active {
        display: block;
    }
`;
const DistrictModal = styled.div`
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;
const DistrictHeading = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 55px;
    padding: 0 25px;
    border-bottom: 1px solid #e7e7e7;
    color: #6b6b6b;
    font-family: "gordita_regular";
    cursor: pointer;
    &:hover {
        background: #e4f8ed;

        color: #4ca473;
    }
`;
const TimeName = styled.div``;
const TimeBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
`;
const TimeCover = styled.div`
    position: absolute;
    top: 71px;
    display: none;
    width: 100%;
    overflow: hidden;
    padding: 5px 0;
    background: #ffff;
    border-radius: 4px;
    z-index: 1000;
    &.active {
        display: block;
    }
`;
const TimeModal = styled.div`
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;
const TimeHeading = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 55px;
    padding: 0 25px;
    border-bottom: 1px solid #e7e7e7;
    color: #6b6b6b;
    font-family: "gordita_regular";
    cursor: pointer;
    &:hover {
        background: #e4f8ed;

        color: #4ca473;
    }
`;
const ArrowContainer = styled.div`
    width: 6%;
    cursor: pointer;
    @media all and (max-width: 980px) {
        width: 6%;
    }
    @media all and (max-width: 480px) {
        width: 6%;
    }
`;
const ArrowIcons = styled.img`
    width: 100%;
    display: block;
`;
const Input = styled.input`
    &::placeholder {
        color: #9f9f9f;
    }
`;
const LinkBox = styled.div`
    display: flex;
    background: #ffffff !important;
    border: 2px solid #d9d9d9 !important;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
`;

const Span = styled.div`
    background: #ebebeb;
    font-family: "gordita_medium";
    border-radius: 8px;
    padding: 10px;
`;

const SubmitButton = styled.div`
    cursor: pointer;
    color: #fff;
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    width: 260px;
    background-color: #1ec569;
    height: 50px;
    font-family: gordita_regular;
    transition: all 0.3s ease 0s;
    margin-top: 50px;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const CloseContainer = styled.div`
    background: #ffffff;
    cursor: pointer;
    border: 1px dashed #afafaf;
    position: absolute;
    top: 0;
    right: 0;
    width: 18%;
    padding: 5px;
    border-radius: 50%;
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    borderRadius: 2,
    marginBottom: 8,
    // marginRight: 8,
    minWidth: 150,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};

const img = {
    display: "block",
    width: "auto",
    height: "100%",
};
